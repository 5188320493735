import React from "react";
import { withRigiContext } from "../context/rigiContext";
import { updateBoilerSettings } from "../../services/devicesService";
import { withForegroundContext } from "../context/ForegroundContext";
import { openSuccessAlert, openErrorAlert } from "../../rigiUtils.js";
import { ButtonCancel } from "../forms/buttons";

class Boiler extends React.Component {
    constructor() {
        super();
    }

    toggelBoilerState(newBoilerState) {
        try {
            updateBoilerSettings(newBoilerState, this.props.rigiContext.rigiUser);
            openSuccessAlert(
                this.props.foregroundContext,
                `Boiler ${newBoilerState ? "eingeschaltet" : "ausgeschaltet"}`
            );
        } catch (e) {
            openErrorAlert(this.props.foregroundContext, e);
        }
    }

    render() {
        const data = this.props.data;
        return (
            <React.Fragment>
                <h2>Boiler</h2>
                <div className='block-boiler'>
                    <div className='block-text-wrapper'>
                        <div className='block-text'>
                            <p className='info'>{data.state.isTurnedOn ? "Ein" : "Aus"}</p>
                            <p className='description'>Aktuell</p>
                        </div>
                        <div className='block-text'>
                            <p className='info'>{data.settings.turnBoilerOn ? "Ein" : "Aus"}</p>
                            <p className='description'>Gewünscht</p>
                        </div>
                    </div>
                    <div className='buttons'>
                        {data.settings.turnBoilerOn ? (
                            <ButtonCancel
                                value='Ausschalten'
                                onClick={() => this.toggelBoilerState(!data.settings.turnBoilerOn)}
                            />
                        ) : (
                            <ButtonCancel
                                value='Einschalten'
                                onClick={() => this.toggelBoilerState(!data.settings.turnBoilerOn)}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRigiContext(withForegroundContext(Boiler));
