import firebase from "../config";
import moment from "moment";

const NOTES_REF = firebase.database().ref(`/notes/`);

export const createNote = async (noteText, rigiUser) => {
    let newPostKey = NOTES_REF.push().key;
    let data = {
        title: noteText,
        id: newPostKey,
        sendEmail: false,
        createdFromUid: rigiUser.uid,
        createdDate: moment().format(),
    };

    await NOTES_REF.child(newPostKey)
        .set(data)
        .then(() => {
            // success, do nothing here
        })
        .catch((error) => {
            throw Error(error);
        });
};

export const finishNote = async (postKey, rigiUser) => {
    let postData = {
        doneFromUid: rigiUser.uid,
        doneDate: moment().format(),
    };
    await NOTES_REF.child(postKey)
        .update(postData)
        .then(() => {
            // success, do nothing here
        })
        .catch((error) => {
            throw Error(error);
        });
};

export const fetchAndMapAndListenToActiveNotes = (updateNotes) => {
    NOTES_REF.on("value", (snap) => {
        let values = snap.val();
        let notes = Object.keys(values)
            .map((key) => values[key])
            .filter((elem) => (elem.doneDate ? false : true));
        updateNotes(notes);
    });
};
