import React from "react";
import moment from "moment";
import { CalendarWidgetWeekRow } from './CalendarWidgetWeekRow.jsx';

export class CalendarWidget extends React.Component {

    constructor(props) {
        super(props);

        let firstDayMomentOfVisibleMonth = this.props.firstDayMomentOfVisibleMonth ? this.props.firstDayMomentOfVisibleMonth : moment()
        this.state = {
            firstDayMomentOfVisibleMonth: firstDayMomentOfVisibleMonth.startOf("month").locale("de"),
        };
        this.generateWeekRow = this.generateWeekRow.bind(this)
    }

    changeCalendarMonth(direction) {
        this.setState({
            firstDayMomentOfVisibleMonth: this.state.firstDayMomentOfVisibleMonth.add(direction, "M")
        });
    }

    generateWeekRow(mondayMomentOfCurWeekRow, firstDayMomentOfVisibleMonth) {
        let weekRowProps = {
            key: mondayMomentOfCurWeekRow.isoWeek(), 
            mondayMoment: mondayMomentOfCurWeekRow.clone(), 
            firstDayMomentOfVisibleMonth: firstDayMomentOfVisibleMonth, 
            calendarEntries: this.props.calendarEntries
        }

        let weekRowComponent = <CalendarWidgetWeekRow {...weekRowProps} />

        let datepickerCalendarWidgetWeekRow = this.props.datepickerCalendarWidgetWeekRow;
        if(datepickerCalendarWidgetWeekRow) {
            weekRowComponent= React.cloneElement(
                datepickerCalendarWidgetWeekRow, 
                {...weekRowProps}
            );
        }

        return weekRowComponent;
    }

    renderWeekRows() {
        let firstDayMomentOfVisibleMonth = this.state.firstDayMomentOfVisibleMonth;
        let weekRows = [];
        let mondayMomentOfCurWeekRow = firstDayMomentOfVisibleMonth.clone()
            .add(-1, "d")
            .day("Montag");
        let isCurWeekRowInMonth = true;
        
        while (isCurWeekRowInMonth) {
            weekRows.push(this.generateWeekRow(mondayMomentOfCurWeekRow, firstDayMomentOfVisibleMonth));
            mondayMomentOfCurWeekRow.add(1, "w");
            isCurWeekRowInMonth = mondayMomentOfCurWeekRow.month() == firstDayMomentOfVisibleMonth.month()
        }

        return weekRows;
    }

    render() {
        let classNames = "calendarWidget"
        if(this.props.id) {
            classNames += " " + this.props.id
        }

        let monthClassName = this.props.onClickMonth ? "clickable" : "";
        let yearClassName = this.props.onClickYear ? "clickable" : "";

        return (
            <div className={classNames} id="calendarWidget">
                <div className="thead">
                    <div className="tr">
                        <div className="td arrow" onClick={() => this.changeCalendarMonth(-1)}>←</div>
                        <div className="td month" >
                            <span className={monthClassName} onClick={this.props.onClickMonth}>{this.state.firstDayMomentOfVisibleMonth.format("MMMM")}</span>
                            <span> </span>
                            <span className={yearClassName} onClick={this.props.onClickYear}>{this.state.firstDayMomentOfVisibleMonth.format("YYYY")}</span>
                        </div>
                        <div className="td arrow" onClick={() => this.changeCalendarMonth(1)}>→</div>
                    </div>
                    <div className="tr">
                        <div className="td weekDays">Mo</div>
                        <div className="td weekDays">Di</div>
                        <div className="td weekDays">Mi</div>
                        <div className="td weekDays">Do</div>
                        <div className="td weekDays">Fr</div>
                        <div className="td weekDays">Sa</div>
                        <div className="td weekDays">So</div>
                    </div>
                </div>
                <div className="tbody">
                    {this.renderWeekRows()}
                </div>
            </div>
        )
    }
}