import React, { createContext } from "react";
import {
    handleAlreadyLoggedInFirebaseUser,
    mapFirebaseUserToRigiUser,
    mapAllFirebaseUsersToAllRigiUsers,
} from "../../services/rigiUserService";
import { fetchAndListenToCalendarEntries, sortOldestFirst } from "../../services/calendarService";
import moment from "moment";

export const RigiContext = createContext();

export const withRigiContext = (WrappedComponent) => (props) =>
    <RigiContext.Consumer>{(value) => <WrappedComponent {...props} rigiContext={value} />}</RigiContext.Consumer>;

export class RigiProvider extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,

            // rigiUser
            rigiUser: null,
            allRigiUsers: null,

            updateRigiUser: (firebaseUser) => {
                this.setRigiUser(firebaseUser);
            },

            // calendarEntries
            calendarEntries: undefined,
            filteredCalendarEntries: undefined,
            currentCalendarEntries: undefined,

            updateFilteredCalendarEntries: (filteredCalendarEntries) => {
                this.updateFilteredCalendarEntries(filteredCalendarEntries);
            },
            fetchAndListenToCalendarEntries: () => {
                this.fetchAndListenToCalendarEntries();
            },
        };

        this.setIsLoading = this.setIsLoading.bind(this);
        this.updateRigiUser = this.setRigiUser.bind(this);
        this.updateCalendarEntries = this.updateCalendarEntries.bind(this);
        this.updateFilteredCalendarEntries = this.updateFilteredCalendarEntries.bind(this);
    }

    componentDidMount() {
        this.fetchAlreadyLoggedInRigiUser();
    }

    fetchAlreadyLoggedInRigiUser() {
        this.setIsLoading(true);
        let onSuccessCallback = async (firebaseUser) => {
            await this.setRigiUser(firebaseUser);
        };
        let hasFinishedCallback = () => {
            this.setIsLoading(false);
        };
        handleAlreadyLoggedInFirebaseUser(onSuccessCallback, hasFinishedCallback);
    }

    fetchAndListenToCalendarEntries() {
        fetchAndListenToCalendarEntries(this.updateCalendarEntries);
    }

    updateCalendarEntries(calendarEntries) {
        sortOldestFirst(calendarEntries);
        this.setState({
            calendarEntries: calendarEntries,
            currentCalendarEntries: this.filterCurrentCalendarEntries(calendarEntries),
        });
    }

    updateFilteredCalendarEntries(filteredCalendarEntries) {
        if (JSON.stringify(this.state.filteredCalendarEntries) != JSON.stringify(filteredCalendarEntries)) {
            this.setState({
                filteredCalendarEntries: filteredCalendarEntries,
            });
        }
    }

    filterCurrentCalendarEntries(calendarEntries) {
        var currentCalendarEntries = [];
        let today = moment().startOf("day");
        calendarEntries.forEach((entry) => {
            var startDate = moment(entry.dateFrom, "YYYY-MM-DD");
            var endDate = moment(entry.dateTo, "YYYY-MM-DD");
            if (today.isSameOrAfter(startDate) && today.isSameOrBefore(endDate)) {
                currentCalendarEntries.push(entry);
            }
        });
        return currentCalendarEntries;
    }

    async setRigiUser(firebaseUser) {
        let isValidFirebaseUser = firebaseUser && firebaseUser.uid;

        // logout
        if (!isValidFirebaseUser) {
            this.setState({
                rigiUser: null,
                allRigiUsers: null,
            });
            return;
        }

        // login
        let rigiUser = {};
        let allRigiUsers = [];
        await Promise.all([
            // will be executed in parallel
            mapFirebaseUserToRigiUser(firebaseUser, rigiUser),
            mapAllFirebaseUsersToAllRigiUsers(allRigiUsers),
        ]);
        this.setState({
            rigiUser: rigiUser,
            allRigiUsers: allRigiUsers,
        });
    }

    setIsLoading(isLoading) {
        this.setState({ isLoading: isLoading });
    }

    render() {
        const { children } = this.props;
        return (
            // This provider will wrap the rest of the tree and we pass in the user in the
            // state and the updateRigiUser function as well.
            <RigiContext.Provider value={this.state}>{children}</RigiContext.Provider>
        );
    }
}
