import React from 'react';
import { withRigiContext } from "../context/rigiContext"
import { withForegroundContext } from "../context/ForegroundContext"
import ChangeMinTempsForm from '../forms/validated/ChangeMinTempsForm.jsx';
import { ButtonCancel } from '../forms/buttons';

class HeatingMinTemps extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const data = this.props.data

		return (
			<React.Fragment>
				<h2>Mindesttemperatur</h2>
				<div className="block-stube">
					<div className="block-text-wrapper">
						<div className="block-text">
							<p className="info celsius">{data.minTempDay}</p>
							<p className="description">Tag</p>
						</div>
						<div className="block-text">
							<p className="info celsius">{data.minTempNight}</p>
							<p className="description">Nacht</p>
						</div>
					</div>
					<div className="buttons">
						<ButtonCancel value="Ändern" onClick={() => this.props.foregroundContext.openModal("Min. Temperaturen ändern", <ChangeMinTempsForm/>)} />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRigiContext(withForegroundContext(HeatingMinTemps));