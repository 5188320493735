import React from 'react';
import { withRigiContext } from "../context/rigiContext"
import { updateStubeSettingsDesiredTemp } from "../../services/devicesService"
import { withForegroundContext } from "../context/ForegroundContext"
import { openSuccessAlert, openErrorAlert } from '../../rigiUtils.js';
import { ButtonMinus, ButtonPlus } from '../forms/buttons';

class Stube extends React.Component {

	constructor(props) {
		super(props);
	}

	changeDesiredTemp(newTemp) {
		try {
			updateStubeSettingsDesiredTemp(newTemp, this.props.rigiContext.rigiUser)
			openSuccessAlert(this.props.foregroundContext, "Stube Temperatur geändert");
		} catch (e) {
			openErrorAlert(this.props.foregroundContext, e);
		}
	}

	decreaseDesiredTemp() {
		this.changeDesiredTemp(this.props.data.settings.desiredTemp - 1)
	}

	increaseDesiredTemp() {
		this.changeDesiredTemp(this.props.data.settings.desiredTemp + 1)
	}

	render() {
		const data = this.props.data
		
		return (
			<React.Fragment>
				<h2>Stube</h2>
				<div className="block-stube">
					<div className="block-text-wrapper">
						<div className="block-text">
							<p className="info celsius">{Math.floor(data.state.currentTemp)}</p>
							<p className="description">Aktuell</p>
						</div>
						<div className="block-text">
							<p className="info celsius">{data.settings.desiredTemp}</p>
							<p className="description">Gewünscht</p>
						</div>
					</div>
					<div className="buttons">
						<ButtonMinus onClick={() => this.decreaseDesiredTemp()} />
						<ButtonPlus onClick={() => this.increaseDesiredTemp()} />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRigiContext(withForegroundContext(Stube));
