import React, { useContext } from 'react'
import { withFormValidation }  from './withFormValidation';
import { ButtonSubmit } from "../buttons"
import { createCalendarEntry } from "../../../services/calendarService"
import { openSuccessAlert, openErrorAlert } from "../../../rigiUtils"
import { CalendarEntryFormSkeleton, initialValues, rules } from './skeletons/CalendarEntryFormSkeleton';


const CalendarEntryNewFormSkeleton = (props) => {

  const {
    onFormValueChange,
    onFormSubmit,
    values,
    fieldErrors,
    isSubmitting,
    clearAllValues
  } = props;

  const handleOnFormSubmit = async () => {
    try {
      await onFormSubmit();
      openSuccessAlert(props.foregroundContext, "Eintrag wurde hinzugefügt");
      clearAllValues()
    } catch (e) {
      openErrorAlert(props.foregroundContext, e, "Eintrag konnte nicht hinzugefügt werden");
    }
  }

  return (
      <CalendarEntryFormSkeleton {...props}>
        <ButtonSubmit
          isSubmitting={isSubmitting}
          value="Eintragen" 
          onClick={handleOnFormSubmit}
        />
      </CalendarEntryFormSkeleton>
  )
}

const handlers = {
  onFormSubmitAfterValidation: async (values, metadata) => {
    try {
      let response = await createCalendarEntry(values, metadata.rigiUser)
      return response
    } catch (error) {
      return error
    }
  }
}

// use Object.assign() to copy initialValues into new object as this messes up with CalendarEntryEditForm and CalendarEntryNewForm

export default withFormValidation(Object.assign({}, initialValues), Object.assign({}, rules), handlers, CalendarEntryNewFormSkeleton);
