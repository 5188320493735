import React from "react";

export class Collapse extends React.Component {
    constructor(props) {
        super(props);
        this.collapsible = React.createRef();
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
    }

    toggleCollapsed() {
        let collapsible = this.collapsible.current;
        collapsible.classList.toggle("collapsed");
        var content = collapsible.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    componentDidMount() {
        if (this.collapsible && this.collapsible.current) {
            this.collapsible.current.addEventListener("click", this.toggleCollapsed);
            if (this.props.initialOpen) {
                setTimeout(this.toggleCollapsed, 500);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={"collapsible-wrapper" + " " + this.props.className}>
                    <div className='collapsible-label' ref={this.collapsible}>
                        <p>{this.props.label}</p>
                        <div className='arrow'></div>
                    </div>

                    <div className='collapsible-content'>
                        <div className='collapsible-content-inner'>{this.props.children}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
