import React from "react";
import moment from "moment";
import { DatepickerCalendarWidgetWeekRow } from './DatepickerCalendarWidgetWeekRow.jsx';
import { CalendarWidget } from './CalendarWidget.jsx';

export class DatepickerCalendarWidget extends React.Component {

    constructor(props) {
        super(props);

        let firstDayMomentOfVisibleMonth = moment()
        if(this.props.isStartDate) {
            if(this.props.startDateMoment) {
                firstDayMomentOfVisibleMonth = this.props.startDateMoment.clone()
            } else if(this.props.endDateMoment) {
                firstDayMomentOfVisibleMonth = this.props.endDateMoment.clone()
            }
        } else if(this.props.isEndDate) {
            if(this.props.endDateMoment) {
                firstDayMomentOfVisibleMonth = this.props.endDateMoment.clone()
            } else if(this.props.startDateMoment) {
                firstDayMomentOfVisibleMonth = this.props.startDateMoment.clone()
            }
        }

        this.state = {
            hoveredDate: null,
            isMonthQuickSelectOpen: false,
            isYearQuickSelectOpen: false,
            selectedMonth: false,
            selectedYear: false,
            startYear: moment().startOf("year").add(-4, "years").locale("de"),
            firstDayMomentOfVisibleMonth: firstDayMomentOfVisibleMonth
        };
        this.onHoverDate = this.onHoverDate.bind(this)
    }

    onHoverDate(hoveredMoment) {
        // if state is null
        if(!this.state.hoveredDate) {
            this.setState({
                hoveredDate: hoveredMoment
            });
        } 
        // if not same date
        if(this.state.hoveredDate && !this.state.hoveredDate.isSame(hoveredMoment)) {
            this.setState({
                hoveredDate: hoveredMoment
            });
        } 
    }

    onMonthSelected(monthIndex) {
        this.setState({
            firstDayMomentOfVisibleMonth: this.state.firstDayMomentOfVisibleMonth.month(monthIndex),
            isMonthQuickSelectOpen: false
        })
    }

    generateMonthQuickSelectItems() {
        let items = [];
        let startOfYear = moment().startOf("year").locale("de")
        let endOfYear = moment().endOf("year").startOf("month").locale("de")

        for (var curMonth = startOfYear; curMonth.diff(endOfYear, 'months') <= 0; curMonth.add(1, "month")) {
            let month = curMonth.format("MMMM");
            let monthIndex = curMonth.format("M") - 1;
            items.push(<p key={month} onClick={() => this.onMonthSelected(monthIndex)}>{month}</p>)
        }

        return items
    }

    onYearSelected(year) {
        this.setState({
            firstDayMomentOfVisibleMonth: this.state.firstDayMomentOfVisibleMonth.year(year),
            isYearQuickSelectOpen: false
        })
    }

    addYear(direction) {
        this.setState({
            startYear: this.state.startYear.add(direction, "year"),
        })
    }

    generateYearQuickSelectItems() {
        let items = [];
        let startYear = this.state.startYear.clone();
        let endYear = startYear.clone().add(9, "years").locale("de")

        items.push(<p key="up" onClick={() => this.addYear(-1)}>↑</p>)
    
        for (var curYear = startYear; curYear.diff(endYear, 'years') <= 0; curYear.add(1, "year")) {
            let year = curYear.format("YYYY");
            items.push(<p key={year} onClick={() => this.onYearSelected(year)}>{year}</p>)
        }

        items.push(<p key="down" onClick={() => this.addYear(1)}>↓</p>)

        return items
    }

    render() {
        const extendedProps = { 
            ...this.props,
            firstDayMomentOfVisibleMonth: this.state.firstDayMomentOfVisibleMonth,
            calendarEntries: [], 
            datepickerCalendarWidgetWeekRow: <DatepickerCalendarWidgetWeekRow 
                onDayClicked={this.props.onDayClicked}
                startDateMoment={this.props.startDateMoment}
                endDateMoment={this.props.endDateMoment}
                onHoverDate={this.onHoverDate}
                hoveredDate={this.state.hoveredDate}
                isStartDate={this.props.isStartDate}
                isEndDate={this.props.isEndDate}
                minDateMoment={this.props.minDateMoment}
            />,
            onClickMonth: () => this.setState({isMonthQuickSelectOpen: true}),
            onClickYear: () => this.setState({isYearQuickSelectOpen: true}),
        }

        return (
            <div>
                <CalendarWidget {...extendedProps} />
                {this.state.isMonthQuickSelectOpen ? 
                    <div className="quickSelect monthSelect">
                        {this.generateMonthQuickSelectItems()}
                    </div> 
                    : null
                }
                {this.state.isYearQuickSelectOpen ? 
                    <div className="quickSelect yearSelect">
                        {this.generateYearQuickSelectItems()}
                    </div>
                    : null
                }
            </div>
        )
    }
}