import React from "react";
import { getFirstnameFromUserKey } from "../../rigiUtils.js";
import NoteCard from "../NoteCard.jsx";
import NoteNewForm from "../forms/validated/NoteNewForm.jsx";
import { withRigiContext } from "../context/rigiContext";
import { fetchAndMapAndListenToActiveNotes } from "../../services/notesService";

class Notes extends React.Component {
    constructor() {
        super();
        this.state = {
            notes: [],
            notesTitleValid: true,
            submittingAddNotes: false,
        };
        this.generateNoteCardsFromData = this.generateNoteCards.bind(this);
        this.updateNotes = this.updateNotes.bind(this);
    }

    componentDidMount() {
        fetchAndMapAndListenToActiveNotes(this.updateNotes);
    }

    updateNotes(notes) {
        this.setState({
            notes: notes,
        });
    }

    generateNoteCards() {
        let notes = this.state.notes;
        let noteCards = [];

        if (notes.length == 0) {
            noteCards.push(
                <p key={1} className='emptyCard emptyNote'>
                    Es gibt momentan nichts zu tun auf der Windegg, schön!
                </p>
            );
        } else {
            for (let i = 0; i < notes.length; i++) {
                let note = notes[i];
                let createdUserFirstname = getFirstnameFromUserKey(
                    this.props.rigiContext.allRigiUsers,
                    note.createdFromUid
                );

                noteCards.push(<NoteCard key={i} note={note} createdUserFirstname={createdUserFirstname} />);
            }
        }

        return noteCards;
    }

    render() {
        let noteCards = this.generateNoteCards();
        return (
            <div className='notes'>
                <div className='allNotes'>{noteCards}</div>
                <NoteNewForm />
            </div>
        );
    }
}

export default withRigiContext(Notes);
