import React from "react";
import EntryCard from "./EntryCard.jsx";
import { withRigiContext } from "../components/context/rigiContext"


class CalendarFilterResults extends React.Component {

    constructor(props) {
        super(props);
    }

    generateFilteredEntryCards() {
        let filteredCalendarEntries = this.props.rigiContext.filteredCalendarEntries;

        let entryCards = [];
        if (filteredCalendarEntries && filteredCalendarEntries.length > 0) {

            filteredCalendarEntries = filteredCalendarEntries.filter(entry => (entry.isDebug == null || entry.isDebug != true));

            for (let i = 0; i < filteredCalendarEntries.length; i++) {
                let entry = filteredCalendarEntries[i];

                const entryCardProps = {
                    ...this.props,
                    key: i,
                    entry: entry,
                    type: "edit"
                }
                entryCards.push(<EntryCard {...entryCardProps} />);
            }
        }
        else {
            entryCards.push(
                <p key={1} className="emptyCard emptyEntry">
                    Es hat momentan niemand geplant, auf die Windegg zu gehen, schade!
                </p>
            );
        }

		return entryCards;
    }

    render() {
        let filteredEntryCards = this.generateFilteredEntryCards();
        return (
            <div>
                {filteredEntryCards}
            </div>
        )
    }
}

export default withRigiContext(CalendarFilterResults);