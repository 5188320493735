import React from 'react';
import AppRouter from "./routing/AppRouter";
import { RigiProvider } from './components/context/rigiContext';
import { ForegroundProvider } from "./components/context/ForegroundContext";

// css
import './index.scss';

export default class App extends React.Component {
	constructor() {
		super();
		this.state = {
			modalTitle: null,
			componentToOpenInModel: null,
			isModalOpen: false,
			componentToOpenInPopup: null,
			isPopupOpen: false,
			rigiUser: {},
		};
	}

	openModal(modalTitle, componentToOpenInModel) {
		this.setState({
			modalTitle: modalTitle,
			componentToOpenInModel: componentToOpenInModel,
			isModalOpen: true
		});
	}

	openPopup(componentToOpenInPopup) {		
		this.setState({
			componentToOpenInPopup: componentToOpenInPopup,
			isPopupOpen: true
		});
	}

	closeModal() {
		this.setState({
			isModalOpen: false
		});
	}

	closePopup() {
		this.setState({
			isPopupOpen: false
		});
	}

	removeModalFromDom() {
		this.setState({
			modalTitle: null,
			componentToOpenInModel: null,
		});
	}

	removePopupFromDom() {
		this.setState({
			componentToOpenInPopup: null
		});
	}

	render() {
		return (
			<RigiProvider>
				<ForegroundProvider>
					<AppRouter />
				</ForegroundProvider>
			</RigiProvider>
		);
	}
}