import React from 'react';

export class Dropbox extends React.Component {
	constructor() {
        super();
	}
    
	render() {
		return (
            <a
                className="dropboxLink"
                target="_blank"
                href="https://www.dropbox.com/sh/faozkgz8xe68a7d/AAD29lp7uarC-nvO59Y9ulVTa?dl=0"
            >
                Dropbox-Link
            </a>
		);
	}
}
