import React from "react";
import CalendarFilterResults from "./CalendarFilterResults.jsx";
import { Collapse } from './Collapse.jsx';
import FilterEntriesFormNew from "./forms/validated/FilterEntriesFormNew.jsx";


export class CalendarFilter extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Collapse label="Einträge suchen">
                    <FilterEntriesFormNew />
                </Collapse>
                <CalendarFilterResults />
            </div>
        )
    }
}
