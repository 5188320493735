import firebase from "../config"
import moment from 'moment';


const DEVICES_REF = firebase.database().ref(`/devices/`)

export const fetchAndMapAndListenToDevices = (updateDevices) => {
	DEVICES_REF.on('value', (snap) => {
		let dbEntry = snap.val();
        updateDevices(dbEntry);
	});
}

export const updateBoilerSettings = async (boilerState, rigiUser) => {
	await DEVICES_REF.child('boiler/settings').update({
        turnBoilerOn: boilerState,
        lastChanged: {
            time: moment().format(),
            userKey: rigiUser.uid
        }
    }).then(() => {
		// success, do nothing here
	}).catch((error) => {
		throw Error(error)
	});
}

export const updateStubeSettingsDesiredTemp = async (newTemp, rigiUser) => {
	await DEVICES_REF.child('stube/settings').update({
        desiredTemp: newTemp,
        lastChangedDesiredTemp: {
            time: moment().format(),
            userKey: rigiUser.uid
        }
    }).then(() => {
		// success, do nothing here
	}).catch((error) => {
		throw Error(error)
	});
}

export const updateStubeSettingsMinTemps = async (values, rigiUser) => {
	await DEVICES_REF.child('stube/settings').update({
        minTempDay: parseInt(values.minTempDay),
        minTempNight: parseInt(values.minTempNight),
        lastChangedMinTemp: {
            time: moment().format(),
            userKey: rigiUser.uid
        }
    }).then(() => {
		// success, do nothing here
	}).catch((error) => {
		throw Error(error)
	});
}
