import React from 'react';
import { CalendarWidget } from '../calendarWidget/CalendarWidget.jsx';
import { CalendarFilter } from '../CalendarFilter.jsx';


export class Calendar extends React.Component {
	constructor() {
		super();
	}

	render() {
		return (
			<div>
				<CalendarWidget 
					calendarEntries={this.props.calendarEntries} 
				/>
				<CalendarFilter
					calendarEntries={this.props.calendarEntries}
					allRigiUsers={this.props.allRigiUsers}
				/>
			</div>
		);
	}
}