import React, { useContext } from 'react'
import { withFormValidation }  from './withFormValidation';
import { ButtonSubmit } from "../buttons"
import { TextareaInputField } from "../inputs"
import { openSuccessAlert, openErrorAlert } from "../../../rigiUtils"
import { createNote } from "../../../services/notesService"
import { ForegroundContext } from '../../context/ForegroundContext';


const NewNoteFormSkeleton = (props) => {

  const {
    onFormValueChange,
    onFormSubmit,
    values,
    fieldErrors,
    isSubmitting,
    clearAllValues
  } = props;

  const handleOnFormSubmit = async () => {
    try {
      await onFormSubmit();
      openSuccessAlert(props.foregroundContext, 'Notiz wurde hinzugefügt');
    } catch (e) {
      openErrorAlert(props.foregroundContext, e, "Notiz konnte nicht hinzugefügt werden");
    }
    clearAllValues();
  }

  return (
    <div>
        <TextareaInputField
            rows="2"
            placeholder="Neue Notiz"
            error={fieldErrors.noteText}
            onChange={onFormValueChange} 
            value={values.noteText}
            name="noteText"
        />
        <ButtonSubmit 
            isSubmitting={isSubmitting}
            value="Notiz hinzufügen"
            onClick={handleOnFormSubmit}
        />
    </div>
  )
}

const initialValues = {
  noteText: ''
};

const rules = {
  noteText: [
    [value => value != '', 'Bitte das Feld ausfüllen!'],
    [value => value.length < 100, `Bitte kurz halten! Nur weniger als 100 Zeichen erlaubt.`]
  ]
};

const handlers = {
  onFormSubmitAfterValidation: async (values, metadata) => {
    try {
      let response = await createNote(values.noteText, metadata.rigiUser)
      return response
    } catch (error) {
      return error
    }
  }
}

export default withFormValidation(Object.assign({}, initialValues), Object.assign({}, rules), handlers, NewNoteFormSkeleton);