import React from 'react';
import moment from 'moment';
import CalendarEntryEditForm from './forms/validated/CalendarEntryEditForm.jsx';
import { withRigiContext } from "./context/rigiContext"
import { withForegroundContext } from "./context/ForegroundContext"
import ConfirmAlert from './context/foreground/ConfirmAlert.jsx';
import { updateCalendarEntryDateTo } from "../services/calendarService"
import { ButtonMinus, ButtonPlus } from '../components/forms/buttons';
import { openSuccessAlert, openErrorAlert } from '../rigiUtils.js';

class EntryCard extends React.Component {
	constructor() {
		super();
    }

    edit() {
        this.props.foregroundContext.openModal("Eintrag bearbeiten", <CalendarEntryEditForm calendarEntryKey={this.props.entry.id}/>);
    }

    updateBooking(entryId, newDateTo, texts) {
        let confirmOnClick = async () => {
            try {
                await updateCalendarEntryDateTo(entryId, newDateTo);
                openSuccessAlert(this.props.foregroundContext, texts.successAlertText);
            } catch (e) {
                openErrorAlert(this.props.foregroundContext, e, texts.errorAlertText);
            }
        }

        this.props.foregroundContext.openPopup(
            <ConfirmAlert 
                closePopup={this.props.foregroundContext.closePopup}
                title={texts.title}
                message={texts.message}
                confirmLabel={texts.confirmLabel}
                confirmOnClick={confirmOnClick}
            />
        );
    }

    shortenBooking() {
        try {
            let texts = {
                successAlertText: "Buchung wurde verkürzt",
                errorAlertText: "Buchung konnte nicht verkürzt werden",
                title: "Buchung verkürzen?",
                message: "Willst du diese Buchung wirklich verkürzen?",
                confirmLabel: "Verkürzen"
            }

            let {entry} = this.props;
            let newDateTo = moment(entry.dateTo, "YYYY-MM-DD").add(-1, 'days').format("YYYY-MM-DD")

            if(moment(newDateTo).isBefore(entry.dateFrom)) {
               throw Error("Du kannst die Buchung nicht in die Vergangenheit verkürzen!")
            }

            this.updateBooking(entry.id, newDateTo, texts)

        } catch (e) {
            openErrorAlert(this.props.foregroundContext, e);
        }
    }

    extendBooking() {
        let texts = {
            successAlertText: "Buchung wurde verlängert",
            errorAlertText: "Buchung konnte nicht verlängert werden",
            title: "Buchung verlängern?",
            message: "Willst du diese Buchung wirklich verlängern?",
            confirmLabel: "Verlängern"
        }

        let {entry} = this.props;
        let newDateTo = moment(entry.dateTo, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
        this.updateBooking(entry.id, newDateTo, texts)
    }

	render() {
        let {entry, withoutYear, withoutComment, type} = this.props;
        let isEditCard = type === "edit";
        let isCurrentBookingCard = type === "currentBooking";

        let dateToFormat = withoutYear ? "DD.MM" : "DD.MM.YYYY"
        let dateFrom = moment(entry.dateFrom, "YYYY-MM-DD").format("DD.MM");
        let dateTo = moment(entry.dateTo, "YYYY-MM-DD").format(dateToFormat);

		return (
			<div className={"card entryCard " 
                + (isEditCard ? "editCard " : "") 
                + (isCurrentBookingCard ? "currentBookingCard " : "")
            }>
                <div className="card-content">
                    <div className="date">{dateFrom} - {dateTo}</div>
                    <div className="person">{entry.person} ({entry.quantity})</div>
                    {!withoutComment && entry.comment &&
                        <div className="comment">{entry.comment}</div>
                    }
                </div>
                <div className="card-action">
                    {isEditCard && 
                        <div className="editEntry" onClick={() => this.edit()}></div> 
                    }
                    {isCurrentBookingCard && 
                        <div className="buttons">
                            <ButtonMinus onClick={() => this.shortenBooking()} />
                            <ButtonPlus onClick={() => this.extendBooking()} />
                        </div>
                    }
                </div>
                
            </div>
		);
	}
}

export default withRigiContext(withForegroundContext(EntryCard));