import React from 'react'
import { InputField, TextareaInputField, DatepickerRangeInputFields, CheckboxField } from "../../inputs"
import moment from 'moment';

export const CalendarEntryFormSkeleton = (props) => {

  const {
    onFormValueChange,
    values,
    fieldErrors,
    children,
    updateValues
  } = props;

  return (
    <div>
        <DatepickerRangeInputFields
            errorStartDate={fieldErrors.startDateMoment}
            momentStartDate={values.startDateMoment}
            nameStartDate="startDateMoment"
            errorEndDate={fieldErrors.endDateMoment}
            momentEndDate={values.endDateMoment}
            nameEndDate="endDateMoment"
            updateValues={(newValues) => updateValues(newValues)}
            minDateMoment={moment()}
        />
        <InputField
            label="Wer"
            type="text"
            placeholder="Name"
            error={fieldErrors.person}
            onChange={onFormValueChange}
            value={values.person}
            name="person"
        />
        <InputField
            label="Anzahl Personen"
            type="number"
            placeholder="Wie viele Personen?"
            error={fieldErrors.quantity}
            onChange={onFormValueChange} 
            value={values.quantity}
            name="quantity"
            inputMode="decimal"
        />
        <TextareaInputField
            label="Kommentar (optional)"
            rows="1"
            placeholder="Kommentar"
            error={fieldErrors.comment}
            onChange={onFormValueChange} 
            value={values.comment}
            name="comment"
        />
        <CheckboxField
            label="Boiler vorheizen"
            id="isWarmWaterRequested"
            error={fieldErrors.isWarmWaterRequested}
            checked={values.isWarmWaterRequested}
            onChange={onFormValueChange}
        />
        <CheckboxField
            label="Stube vorheizen"
            id="isPreheatingRequested"
            error={fieldErrors.isPreheatingRequested}
            checked={values.isPreheatingRequested}
            onChange={onFormValueChange} 
        />
        <div className="buttonGroup">
            {children}
        </div>
    </div>
  )
}

export const initialValues = {
  startDateMoment: '',
  endDateMoment: '',
  person: '',
  quantity: '',
  comment: '',
  isWarmWaterRequested: false,
  isPreheatingRequested: false,
};

export const rules = {
  startDateMoment: [
    [value => value != '', 'Bitte gib ein Datum ein'],
  ],
  endDateMoment: [
    [value => value != '', 'Bitte gib ein Datum ein'],
  ],
  person: [
    [value => value != '', 'Bitte gib ein Wer ein'],
  ],
  quantity: [
    [value => value != '' && Number.isInteger(Number(value)) && Number(value) > 0, 'Bitte gib eine (positive) Anzahl Personen ein'],
  ],
  comment: [
    [value => value.length <= 50, 'Bitte gib maximal 50 Zeichen ein!'],
  ]
};