import React from 'react';
import { withRigiContext } from "../context/rigiContext"

class Header extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
        <div className="header-wrapper">
            <div className="header">
              <h1 className="header-title">Rigi Windegg</h1>
            </div>
        </div>
    );
  }
}

export default withRigiContext(Header);