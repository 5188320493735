import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import AuthRoute from "./routes/AuthRoute";
import UnauthRoute from "./routes/UnauthRoute";
import { withRigiContext } from "../components/context/rigiContext"

class AppRouter extends React.Component {

    isAuthenticated() {
        return this.props.rigiContext.rigiUser !== null;
    }

    render() {      
        return (
            <BrowserRouter>
                <Route render={({ location }) => {
                    let isAuth = this.isAuthenticated()
                    return (
                        <Switch location={location}>
                            <UnauthRoute exact path='/login' component={Login} isAuth={isAuth}/>
                            <AuthRoute exact path='/home' component={Home} isAuth={isAuth}/>
                            <Redirect from="*" to="/login" />
                        </Switch>
                    )
                }}/>
            </BrowserRouter>
        );
    }
}

export default withRigiContext(AppRouter);