import React from "react";
import moment from "moment";

export class DatepickerCalendarWidgetDayCell extends React.Component {

    constructor() {
        super();
        this.dayCell = React.createRef();
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mouseover', this.handleMouseOver);
        document.addEventListener('mouseout', this.handleMouseOut);

    }

    componentWillUnmount() {
        document.removeEventListener('mouseover', this.handleMouseOver);
        document.addEventListener('mouseout', this.handleMouseOut);
    }

    onDayClicked(dayMoment) {
        if(dayMoment && this.props.onDayClicked) {
            if(!this.props.minDateMoment || !(this.props.minDateMoment && this.props.minDateMoment.isAfter(dayMoment))) {
                this.props.onDayClicked(dayMoment);
            }
        }
    }

    handleMouseOver(event) {
        if (this.dayCell.current && this.dayCell.current.contains(event.target)) {
            let {curDayMoment} = this.props
            this.props.onHoverDate(curDayMoment)
        }
    }

    handleMouseOut(event) {
        if (this.dayCell.current && this.dayCell.current.contains(event.target)) {
            this.props.onHoverDate(null)
        }
    }

    render() {
        let {curDayMoment, dayLabel} = this.props
        let {firstDayMomentOfVisibleMonth, startDateMoment, endDateMoment, hoveredDate, isStartDate, isEndDate, minDateMoment} = this.props

        let dayMoment = dayLabel ? moment(firstDayMomentOfVisibleMonth.startOf("day")).set('date', dayLabel) : null;

        let classNames = ["td"];

        if(startDateMoment && dayMoment && dayMoment.isSame(startDateMoment, "day")) {
            classNames.push("startDate");
        }
        if(endDateMoment && dayMoment && dayMoment.isSame(endDateMoment, "day")) {
            classNames.push("endDate");
        }

        if(!dayMoment) {
            classNames.push("disabled");
        } else if(minDateMoment && dayMoment.isBefore(minDateMoment.startOf("day"))) {
            classNames.push("not-selectable");
        } else if (startDateMoment && endDateMoment && dayMoment.isBetween(startDateMoment.startOf("day"), endDateMoment.startOf("day"), null, '()')) {
            classNames.push("in-range");
        }

        if(isStartDate) {
            classNames.push("isStartDateCandidate");
        }
        if(isEndDate) {
            classNames.push("isEndDateCandidate");
        }

        // on hover new date
        let isInSelectingRange = false;
        if(hoveredDate && !classNames.includes("disabled")) {
            // remove in-range 
            var index = classNames.indexOf("in-range");
            if (index >= 0) {
                classNames.splice(index,1);
            }

            if(startDateMoment) {

                if(isStartDate) {
                    var index = classNames.indexOf("startDate");
                    if (index >= 0) {
                        classNames.splice(index,1);
                    }
                } else if(curDayMoment.isBetween(startDateMoment, hoveredDate, null, '()')) {
                    isInSelectingRange = true;
                } else if(curDayMoment.isSame(hoveredDate) && curDayMoment.isSame(startDateMoment)) {
                    classNames.push("same-date");
                } 
            } else {
                if(isStartDate && curDayMoment.isSame(hoveredDate)) {
                    classNames.push("startDate");
                }
            }

            if(endDateMoment) {

                if(isEndDate) {
                    var index = classNames.indexOf("endDate");
                    if (index >= 0) {
                        classNames.splice(index,1);
                    }
                } else if(curDayMoment.isBetween(hoveredDate, endDateMoment, null, '()')) {
                    isInSelectingRange = true;
                } else if(curDayMoment.isSame(hoveredDate) && curDayMoment.isSame(endDateMoment)) {
                    classNames.push("same-date");
                }
            } else {
                if(isEndDate && curDayMoment.isSame(hoveredDate)) {
                    classNames.push("endDate");
                }
            }
        }

        if(isInSelectingRange) {
            classNames.push("in-selecting-range");
        }

        return (
            <div
                onClick={() => this.onDayClicked(dayMoment)}
                ref={this.dayCell}
                className={classNames.join(" ")}
            >
                {dayLabel}
            </div>
        )
    }
}



