import React from "react";
import moment from "moment";
import { CalendarWidgetDayCell } from './CalendarWidgetDayCell.jsx';

export class CalendarWidgetWeekRow extends React.Component {

    constructor() {
        super();
        this.generateDayCell = this.generateDayCell.bind(this);
    }

    generateDayCell(curDayMoment, isBooked, dataTip, dayLabel) {
        let dayCellProps = {
            key: curDayMoment.toString(),
            curDayMoment: curDayMoment.clone(),
            dayLabel: dayLabel,
            isBooked: isBooked,
            dataTip: dataTip
        }

        let dayCellComponent = <CalendarWidgetDayCell {...dayCellProps} />

        let datepickerCalendarWidgetDayCell = this.props.datepickerCalendarWidgetDayCell;
        if(datepickerCalendarWidgetDayCell) {
            dayCellComponent= React.cloneElement(
                datepickerCalendarWidgetDayCell, 
                {...dayCellProps}
            );
        }

        return dayCellComponent;
    }

    generateWeekRow() {
        let weekRow = [];
        let firstDayMomentOfVisibleMonth = this.props.firstDayMomentOfVisibleMonth;
        let calendarEntries = this.props.calendarEntries;
        var mondayMoment = this.props.mondayMoment;
        var sundayMoment = moment(this.props.mondayMoment).add(7, "day");

        for (var curDayMoment = moment(mondayMoment); curDayMoment.diff(sundayMoment, 'days') < 0; curDayMoment.add(1, "day")) {
            var dayLabel = null;
            if (curDayMoment.month() == firstDayMomentOfVisibleMonth.month()) {
                dayLabel = curDayMoment.date()
            }

            let isBooked = false;
            let dataTip = "";
            for (let i = 0; i < calendarEntries.length; i++) {
                let curEntry = calendarEntries[i];
                if (curDayMoment.isBetween(curEntry.dateFrom, curEntry.dateTo, null, '[]')) {
                    isBooked = true;
                    let curDataTip = curEntry.person + " (" + curEntry.quantity + ")";
                    if(dataTip != "") {
                        curDataTip = dataTip + " & " + curDataTip;
                    }
                    dataTip = curDataTip;
                }
            }

            weekRow.push(this.generateDayCell(curDayMoment, isBooked, dataTip, dayLabel));
        }

        return weekRow;
    }

    render() {
        return (
            <div className="tr">
                {this.generateWeekRow()}
            </div>
        )
    }
}