import React from 'react';
import EntryCard from "../EntryCard.jsx";

export class CurrentBookings extends React.Component {
	constructor() {
		super();
	}
 
    render() {
        return (
            <div>
                {this.props.currentBookings.map((entry, index) => {
                    return <EntryCard key={index} entry={entry} withoutYear withoutComment type="currentBooking"/>
                })}
            </div>
        )
    }
}