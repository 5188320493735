import React from "react";
import moment from "moment";
import ConfirmAlert from "./context/foreground/ConfirmAlert.jsx";
import { openSuccessAlert, openErrorAlert } from "../rigiUtils.js";
import { withRigiContext } from "./context/rigiContext";
import { withForegroundContext } from "./context/ForegroundContext";
import { finishNote } from "../services/notesService";

class NoteCard extends React.Component {
    constructor() {
        super();
    }

    handleFinishNote() {
        let confirmOnClick = async () => {
            try {
                await finishNote(this.props.note.id, this.props.rigiContext.rigiUser);
                openSuccessAlert(this.props.foregroundContext, "Notiz wurde erledigt");
            } catch (e) {
                openErrorAlert(this.props.foregroundContext, e, "Notiz konnte nicht erledigt werden");
            }
        };

        this.props.foregroundContext.openPopup(
            <ConfirmAlert
                closePopup={this.props.foregroundContext.closePopup}
                title='Notiz erledigt?'
                message='Bei Erledigung der Notiz wird sie ins Archiv gelegt.'
                confirmLabel='Erledigt'
                confirmOnClick={confirmOnClick}
            />
        );
    }

    render() {
        let createdDate = moment(this.props.note.createdDate).format("DD. MMM YYYY");

        return (
            <div className='card noteCard'>
                <a className='done' onClick={() => this.handleFinishNote()} />
                <div className='wrapperTitle'>
                    <p className='title'>{this.props.note.title}</p>
                    <div className='createdBy'>
                        {createdDate} von {this.props.createdUserFirstname}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRigiContext(withForegroundContext(NoteCard));
