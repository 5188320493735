import React from "react";
import Header from "../components/sections/Header.jsx";
import { Dropbox } from "../components/sections/Dropbox.jsx";
import Dashboard from "../components/sections/Dashboard.jsx";
import { Collapse } from "../components/Collapse.jsx";
import Notes from "../components/sections/Notes.jsx";
import { Calendar } from "../components/sections/Calendar.jsx";
import { NewEntry } from "../components/sections/NewEntry.jsx";
import { CurrentBookings } from "../components/sections/CurrentBookings.jsx";
import Footer from "../components/sections/Footer.jsx";
import { DarkGreySection, LightGreySection, WhiteSection } from "../components/sections/sections";
import { withRouter } from "react-router-dom";
import { withRigiContext } from "../components/context/rigiContext";

class Home extends React.Component {
    constructor() {
        super();
    }

    componentDidMount() {
        this.props.rigiContext.fetchAndListenToCalendarEntries();
    }

    render() {
        let { calendarEntries, currentCalendarEntries } = this.props.rigiContext;
        let hasCurrentCalendarEntries = currentCalendarEntries && currentCalendarEntries.length > 0;

        return (
            <div>
                <Header />
                {hasCurrentCalendarEntries !== undefined && (
                    <WhiteSection>
                        <Collapse
                            label='Heizungsregler'
                            className='dashboard-wrapper'
                            initialOpen={hasCurrentCalendarEntries}>
                            <Dashboard />
                        </Collapse>
                    </WhiteSection>
                )}
                {hasCurrentCalendarEntries && (
                    <LightGreySection title='Aktuelle Buchungen'>
                        <CurrentBookings currentBookings={currentCalendarEntries} />
                    </LightGreySection>
                )}
                <DarkGreySection title='Notizen'>
                    <Notes />
                </DarkGreySection>
                {calendarEntries && (
                    <LightGreySection title='Hüttenbelegung'>
                        <Calendar calendarEntries={calendarEntries} />
                    </LightGreySection>
                )}
                <DarkGreySection title='Neuer Eintrag'>
                    <NewEntry />
                </DarkGreySection>
                <Footer />
            </div>
        );
    }
}

export default withRigiContext(withRouter(Home));
