import firebase from "../config";
import moment from "moment";

const CALENDAR = "calendar";

const mapUserData = (data, rigiUser) => {
    data.userEmail = rigiUser.email;
    data.userKey = rigiUser.uid;
};

const mapCalendarEntryData = (data, values) => {
    data.dateFrom = values.startDateMoment.format("YYYY-MM-DD");
    data.dateTo = values.endDateMoment.format("YYYY-MM-DD");
    data.person = values.person;
    data.quantity = values.quantity;
    data.comment = values.comment;
    data.isWarmWaterRequested = values.isWarmWaterRequested ? true : false;
    data.isPreheatingRequested = values.isPreheatingRequested ? true : false;
};

const handleRaspberryNotificationDateFrom = (data, prevData) => {
    let hasDateFromChanged = !moment(prevData.dateFrom).isSame(data.dateFrom, "day");
    if (hasDateFromChanged) {
        data.raspberryNotifications = {
            isUpcomingBookingConfirmed: false,
            isEndingBookingConfirmed: prevData.raspberryNotifications
                ? prevData.raspberryNotifications.isEndingBookingConfirmed
                : false,
        };
    }
};

const handleRaspberryNotificationDateTo = (data, prevData) => {
    let hasDateToChanged = !moment(prevData.dateTo).isSame(data.dateTo, "day");
    if (hasDateToChanged) {
        if (data.raspberryNotifications) {
            data.raspberryNotifications.isEndingBookingConfirmed = false;
        } else {
            data.raspberryNotifications = {
                isUpcomingBookingConfirmed: prevData.raspberryNotifications
                    ? prevData.raspberryNotifications.isUpcomingBookingConfirmed
                    : false,
                isEndingBookingConfirmed: false,
            };
        }
    }
};

const storeCalendarEntry = async (data) => {
    await firebase
        .database()
        .ref(`/${CALENDAR}/` + data.id)
        .update(data)
        .then(() => {
            // success, do nothing here
        })
        .catch((error) => {
            console.log(error);
            throw Error(error);
        });
};

const storeCalendarEntryRaspberryNotifications = async (id, data) => {
    await firebase
        .database()
        .ref(`/${CALENDAR}/` + id + "/raspberryNotifications")
        .update(data)
        .then(() => {
            // success, do nothing here
        })
        .catch((error) => {
            throw Error(error);
        });
};

const createCalendarEntryInternal = async (data, values, rigiUser) => {
    mapUserData(data, rigiUser);
    mapCalendarEntryData(data, values);
    data.raspberryNotifications = {
        isUpcomingBookingConfirmed: false,
        isEndingBookingConfirmed: false,
    };
    await storeCalendarEntry(data);
};

const updateCalendarEntryInternal = async (data, values) => {
    mapCalendarEntryData(data, values);

    let prevData = {};
    await fetchCalendarEntry(data.id, prevData);

    handleRaspberryNotificationDateFrom(data, prevData);
    handleRaspberryNotificationDateTo(data, prevData);

    await storeCalendarEntry(data);
};

export const createCalendarEntry = async (values, rigiUser) => {
    let id = firebase.database().ref().child(CALENDAR).push().key;
    let data = { id: id };
    await createCalendarEntryInternal(data, values, rigiUser);
};

export const updateCalendarEntry = async (id, values) => {
    let data = { id: id };
    await updateCalendarEntryInternal(data, values);
};

export const updateCalendarEntryDateTo = async (id, newDateTo) => {
    await storeCalendarEntryRaspberryNotifications(id, { isEndingBookingConfirmed: false });
    await storeCalendarEntry({ id: id, dateTo: newDateTo });
};

export const fetchAndListenToCalendarEntries = (callbackFunction) => {
    firebase
        .database()
        .ref(`/${CALENDAR}/`)
        .on("value", (snapshot) => {
            let value = snapshot.val();
            let calendarEntries = Object.keys(value).map((key) => value[key]);
            callbackFunction(calendarEntries);
        });
};

const fetchCalendarEntry = async (id, data) => {
    await firebase
        .database()
        .ref(`/${CALENDAR}/` + id)
        .once("value", (snap) => {
            let dbEntry = snap.val();
            if (!dbEntry) {
                throw Error("No calendar entry found for id:" + id);
            }
            Object.assign(data, dbEntry);
        });
};

export const fetchAndMapCalendarEntryForEditForm = async (id, data) => {
    // fetch
    let dbEntry = {};
    await fetchCalendarEntry(id, dbEntry);

    // map value strictly for every initialValue on edit form
    data.startDateMoment = moment(dbEntry.dateFrom, "YYYY-MM-DD");
    data.endDateMoment = moment(dbEntry.dateTo, "YYYY-MM-DD");
    data.person = dbEntry.person;
    data.quantity = dbEntry.quantity;
    data.comment = dbEntry.comment ? dbEntry.comment : "";
    data.isWarmWaterRequested = dbEntry.isWarmWaterRequested == undefined ? false : dbEntry.isWarmWaterRequested;
    data.isPreheatingRequested = dbEntry.isPreheatingRequested == undefined ? false : dbEntry.isPreheatingRequested;
};

export const deleteCalendarEntry = async (id) => {
    await firebase
        .database()
        .ref(`/${CALENDAR}/` + id)
        .remove()
        .then(() => {
            // success, do nothing
        })
        .catch((error) => {
            throw Error(error);
        });
};

export const sortOldestFirst = (calendarEntries) => {
    calendarEntries.sort(function (a, b) {
        let aFrom = new Date(a.dateFrom);
        let bFrom = new Date(b.dateFrom);
        let aTo = new Date(a.dateTo);
        let bTo = new Date(b.dateTo);

        if (aFrom < bFrom) {
            return -1;
        } else if ((aFrom = bFrom)) {
            if (aTo < bTo) {
                return -1;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    });
};
