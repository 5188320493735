import React, { useContext } from 'react'
import { withFormValidation }  from './withFormValidation';
import { ButtonSubmit } from "../buttons"
import { InputField } from "../inputs"
import { updateStubeSettingsMinTemps } from "../../../services/devicesService"
import { openErrorAlert, openSuccessAlert } from "../../../rigiUtils"


const ChangeMinTempsForm = (props) => {
  const {
    onFormValueChange,
    onFormSubmit,
    values,
    fieldErrors,
    isSubmitting
  } = props;

  const handleOnFormSubmit = async () => {
    try {
        await onFormSubmit();
        openSuccessAlert(props.foregroundContext, 'Min. Temperaturen erfolgreich geändert');
		    props.foregroundContext.closeModal();
    } catch (e) {
        openErrorAlert(props.foregroundContext, e);
    }
  }

  return (
    <div>
        <InputField
            label="Min. Temperatur Tag"
            type="number"
            placeholder="Min. Temperatur Tag"
            error={fieldErrors.minTempDay}
            onChange={onFormValueChange} 
            value={values.minTempDay}
            name="minTempDay"
        />
        <InputField
            label="Min. Temperatur Nacht"
            type="number"
            placeholder="Min. Temperatur Nacht"
            error={fieldErrors.minTempNight}
            onChange={onFormValueChange} 
            value={values.minTempNight}
            name="minTempNight"
        />
        <ButtonSubmit
            isSubmitting={isSubmitting}
            value="Ändern" 
            onClick={handleOnFormSubmit}
        />
    </div>
  )
}

const initialValues = {
  minTempDay: '',
  minTempNight: '',
};

const rules = {
  minTempDay: [
    [value => value != '' && Number.isInteger(Number(value)), 'Bitte gib eine Zahl ein'],
  ],
  minTempNight: [
    [value => value != '' && Number.isInteger(Number(value)), 'Bitte gib eine Zahl ein'],
  ]
};

const handlers = {
  onFormSubmitAfterValidation: async (values, metadata) => {
    try {
      let response = await updateStubeSettingsMinTemps(values, metadata.rigiUser)
      return response
    } catch (error) {
      return error
    }
  }
}

export default withFormValidation(initialValues, rules, handlers, ChangeMinTempsForm);