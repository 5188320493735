import React from "react";
import ReactTooltip from 'react-tooltip';

export class CalendarWidgetDayCell extends React.Component {

    constructor() {
        super();
    }

    render() {
        let {curDayMoment, dayLabel} = this.props

        let dayCell = null;
        if(curDayMoment.isSame(new Date(), "day")) {
            dayLabel = <span className="circleToday">{dayLabel}</span> 
        }

        if(this.props.isBooked) {
            let {dataTip} = this.props
            dayCell = <div
                data-tip={dataTip}
                data-event="click mouseover"
                data-for={curDayMoment.toString()}
                className="td red"
            >
                {dayLabel}
                <ReactTooltip id={curDayMoment.toString()} effect="solid" globalEventOff='mouseout'/>
            </div>
        } else {
            dayCell = <div className="td">
                {dayLabel}
            </div>
        }

        return dayCell
    }
}