import React from 'react';
import HeatingMinTemps from '../devices/HeatingMinTemps.jsx';
import DevicesBox from '../devices/DevicesBox.jsx';
import Stube from '../devices/Stube.jsx';
import Boiler from '../devices/Boiler.jsx';
import { withRigiContext } from "../context/rigiContext"
import { fetchAndMapAndListenToDevices } from "../../services/devicesService"


class Dashboard extends React.Component {
	
	constructor() {
		super();
		this.state = {
			devices: null
		};
        
        this.updateDevices = this.updateDevices.bind(this);
	}

	componentDidMount() {
        fetchAndMapAndListenToDevices(this.updateDevices)
	}

    updateDevices(devices) {
    	this.setState({
    		devices: devices
    	});
    }
	
	render() {
		return (
			<React.Fragment>
				{ this.state.devices 
					? <div className="dashboard">
						<DevicesBox component={<Stube/>} data={this.state.devices.stube} settingsLastChanged={this.state.devices.stube.settings.lastChangedDesiredTemp} />
						<DevicesBox component={<Boiler/>} data={this.state.devices.boiler} settingsLastChanged={this.state.devices.boiler.settings.lastChanged} />
						{ this.props.rigiContext.rigiUser.isAdmin && <DevicesBox component={<HeatingMinTemps/>} data={this.state.devices.stube.settings} settingsLastChanged={this.state.devices.stube.settings.lastChangedMinTemp} /> }
		              </div>
		            : <div className="loading loading-black"/>
		        }
	        </React.Fragment>
		);
	}
}

export default withRigiContext(Dashboard);
