import React from 'react';
import LoginForm from '../components/forms/validated/LoginForm.jsx';
import ResetPasswordForm from '../components/forms/validated/ResetPasswordForm.jsx';
import { withRouter } from 'react-router-dom';
import { withRigiContext } from "../components/context/rigiContext"

class Login extends React.Component {

	constructor() {
		super();
		this.state = {
			isLoginFormVisible: true,
		};
	}

	showLoginForm(isLoginFormVisible) {
		this.setState({
			isLoginFormVisible: isLoginFormVisible
		});
	}

	render() {
		let isLoading = this.props.rigiContext.isLoading
		let loadingVisibility = isLoading ? "block" : "none";
		let contentVisibility = isLoading ? "none" : "block";

		return (
			<div className="login">
				<div className="loading" style={{display: loadingVisibility}}/>
				<div className="content" style={{display: contentVisibility}}>
					<div className="logo" />
					<h1>Rigi Windegg</h1>
					{this.state.isLoginFormVisible ? 
						<LoginForm openResetPasswordForm={() => this.showLoginForm(false)} /> : 
						<ResetPasswordForm openLoginForm={() => this.showLoginForm(true)} />
					}			
				</div>;
			</div>
		);
	}
}

export default withRouter(withRigiContext(Login));