import React, { useContext } from 'react'
import { withFormValidation }  from './withFormValidation';
import { ButtonSubmit, ButtonDelete } from "../buttons"
import { fetchAndMapCalendarEntryForEditForm, deleteCalendarEntry, updateCalendarEntry } from "../../../services/calendarService"
import { openSuccessAlert, openErrorAlert } from "../../../rigiUtils"
import { CalendarEntryFormSkeleton, initialValues, rules } from './skeletons/CalendarEntryFormSkeleton';
import ConfirmAlert from '../../context/foreground/ConfirmAlert.jsx';

class CalendarEntryEditFormSkeleton extends React.Component {

    async componentDidMount() {
        try {
            // TODO loading screen while loading data
            let calendarEntryKey = this.props.calendarEntryKey
            if(!calendarEntryKey) {
                throw Error("No calendarEntryKey provided")
            }
            this.props.foregroundContext.setPostKey(calendarEntryKey)

            let data = {}
            await fetchAndMapCalendarEntryForEditForm(calendarEntryKey, data)
            this.props.updateValues(data)            
        } catch(e) {
            openErrorAlert(this.props.foregroundContext, e, "Fehler beim Öffnen des Kalender-Eintrags")
        }
	}

    componentWillUnmount() {
        this.props.clearAllValues()
        this.props.foregroundContext.setPostKey(null)
    }

    handleOnFormSubmit = async () => {
        try {
            await this.props.onFormSubmit();
            openSuccessAlert(this.props.foregroundContext, "Eintrag wurde geändert");
            this.props.foregroundContext.closeModal();
        } catch (e) {
            openErrorAlert(this.props.foregroundContext, e, "Eintrag konnte nicht geändert werden");
        }
    }

    handleOnDelete = () => {
        let confirmOnClick = async () => {
            try {
                await deleteCalendarEntry(this.props.calendarEntryKey);
                openSuccessAlert(this.props.foregroundContext, "Eintrag wurde gelöscht");
                this.props.foregroundContext.closeModal();
            } catch (e) {
                openErrorAlert(this.props.foregroundContext, e, "Eintrag konnte nicht gelöscht werden");
            }
        }

        this.props.foregroundContext.openPopup(
            <ConfirmAlert 
                closePopup={this.props.foregroundContext.closePopup}
                title="Eintrag löschen?"
                message="Der Eintrag wird generell gelöscht und bei allen verschwinden."
                confirmLabel="Löschen"
                confirmOnClick={confirmOnClick}
            />
        );
    }

    render() {
        const {
            isSubmitting,
        } = this.props;
        return (
            <CalendarEntryFormSkeleton {...this.props}>
                <ButtonSubmit
                    isSubmitting={isSubmitting}
                    value="Ändern" 
                    onClick={this.handleOnFormSubmit}
                />
                <ButtonDelete
                    onClick={this.handleOnDelete}
                />
            </CalendarEntryFormSkeleton>
        );
	}
}

const handlers = {
  onFormSubmitAfterValidation: async (values, metadata) => {
    try {
        let response = await updateCalendarEntry(metadata.postKey, values)
        return response
    } catch (error) {
        console.log(error);
        return error
    }
  }
}

// use Object.assign() to copy initialValues into new object as this messes up with CalendarEntryEditForm and CalendarEntryNewForm

export default withFormValidation(Object.assign({}, initialValues), Object.assign({}, rules), handlers, CalendarEntryEditFormSkeleton);
