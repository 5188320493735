import React from "react";
import { CalendarWidgetWeekRow } from './CalendarWidgetWeekRow.jsx';
import { DatepickerCalendarWidgetDayCell } from './DatepickerCalendarWidgetDayCell.jsx';

export class DatepickerCalendarWidgetWeekRow extends React.Component {

    constructor() {
        super();
    }

    render() {
        const extendedProps = { 
            ...this.props,
            datepickerCalendarWidgetDayCell: <DatepickerCalendarWidgetDayCell
                    firstDayMomentOfVisibleMonth={this.props.firstDayMomentOfVisibleMonth}
                    onDayClicked={this.props.onDayClicked}
                    startDateMoment={this.props.startDateMoment}
                    endDateMoment={this.props.endDateMoment}
                    onHoverDate={this.props.onHoverDate}
                    hoveredDate={this.props.hoveredDate}
                    isStartDate={this.props.isStartDate}
                    isEndDate={this.props.isEndDate}
                    minDateMoment={this.props.minDateMoment}
            />,
        }

        return (
            <CalendarWidgetWeekRow 
                    {...extendedProps} 
            />
        )
    }
}


