import React, { useContext, useEffect } from 'react'
import moment from 'moment';
import { withFormValidation }  from './withFormValidation';
import { DatepickerRangeInputFields, MultiSelectField, CheckboxField } from '../inputs';
import { ButtonSubmit, ButtonDelete, ButtonCancel } from "../buttons";
import { openSuccessAlert, openErrorAlert, generatePdfFromCalendarEntries } from '../../../rigiUtils.js';


const FilterEntriesFormNewSkeleton = (props) => {

  const {
    onFormValueChange,
    onFormSubmit,
    values,
    fieldErrors,
    isSubmitting,
    clearAllValues,
    updateValues,
    calendarEntries
  } = props;

  /* maybe used later for db call
  const handleOnFormSubmit = async () => {
    try {
        await onFormSubmit();
        filterCalendarEntries();
        openSuccessAlert(props.foregroundContext, "Filter angewendet");
    } catch (e) {
        openErrorAlert(props.sforegroundContext, e, "Filter konnte nicht angewendet werden");
    }
  }*/

  const handlePrint = async() => {
    let startDateMoment = values.startDateMoment
    let endDateMoment = values.endDateMoment
    let filteredCalendarEntries = props.rigiContext.filteredCalendarEntries;
    let allRigiUsers = props.rigiContext.allRigiUsers

    try {
        await generatePdfFromCalendarEntries(startDateMoment, endDateMoment, filteredCalendarEntries, allRigiUsers)
        openSuccessAlert(props.foregroundContext, "PDF wurde erstellt")
    } catch(e) {
        openErrorAlert(props.foregroundContext, e, "PDF konnte nicht erstellt werden")
    }
  }

  // same as componentDidMount()
  useEffect(() => {
    filterCalendarEntries();
  });

  const filterCalendarEntries = () => {
    let calendarEntries = props.rigiContext.calendarEntries;
    let startDateMoment = values.startDateMoment;
    let endDateMoment = values.endDateMoment;
    let filteredUsers = values.filteredUsers;

    let filteredCalendarEntries = calendarEntries.filter((entry) => {
        let isEntryFiltered = true;
        if(startDateMoment && startDateMoment.isValid()) {
          	startDateMoment = startDateMoment.startOf("day");
            let entryDateToMoment = moment(entry.dateTo, "YYYY-MM-DD")
            isEntryFiltered = isEntryFiltered && startDateMoment.isSameOrBefore(entryDateToMoment)
        }
        if(isEntryFiltered && endDateMoment && endDateMoment.isValid()) {
            endDateMoment = endDateMoment.startOf("day");
            let entryDateFromMoment = moment(entry.dateFrom, "YYYY-MM-DD")
            isEntryFiltered = isEntryFiltered && entryDateFromMoment.isSameOrBefore(endDateMoment)
        }
        if(isEntryFiltered && filteredUsers.length > 0) {
            let filteredUserKeys = Object.keys(filteredUsers).map((key) => filteredUsers[key]["value"]);
            isEntryFiltered = isEntryFiltered && filteredUserKeys.includes(entry.userKey)
        }
        return isEntryFiltered;
    });

    props.rigiContext.updateFilteredCalendarEntries(filteredCalendarEntries);
  }

  const toggleOnlyMine = () => {
    let selectedOptions = [];
    if(!values.isOnlyMine) {
        selectedOptions.push({
            value: props.rigiContext.rigiUser.uid,
            label: props.rigiContext.rigiUser.firstname
        })
    };

    let data = {
        isOnlyMine: !values.isOnlyMine,
        filteredUsers: selectedOptions
    };

    props.updateValues(data);
  }

  return (
    <div>
      <DatepickerRangeInputFields
          momentStartDate={values.startDateMoment}
          nameStartDate="startDateMoment"
          errorStartDate={fieldErrors.startDateMoment}
          momentEndDate={values.endDateMoment}
          nameEndDate="endDateMoment"
          errorEndDate={fieldErrors.endDateMoment}
          updateValues={(newValues) => updateValues(newValues)}
      />
      <MultiSelectField
          label="Personen"
          placeholder="Personen wählen..."
          value={values.filteredUsers}
          options={props.rigiContext.allRigiUsers}
          error={fieldErrors.filteredUsers}
          name="filteredUsers"
          onChange={(newValues) => updateValues({filteredUsers: newValues})}
      />
      <CheckboxField
          label="Nur meine Einträge"
          id="isOnlyMine"
          checked={values.isOnlyMine}
          error={fieldErrors.isOnlyMine}
          name="isOnlyMine"
          onChange={onFormValueChange}
          onChangeCallback={toggleOnlyMine}
      />
      <div className="buttonGroup">
          <ButtonDelete
              value="Zurücksetzen"
              onClick={clearAllValues}
          />
          {props.rigiContext.rigiUser.isAdmin &&
              <ButtonCancel
                  isSubmitting={values.isPrintSubmitting}
                  value="Drucken"
                  onClick={handlePrint}
              />
          }
      </div>
    </div>
  )
}

const initialValues = {
  startDateMoment: moment(),
  endDateMoment: null,
  filteredUsers: [],
  isOnlyMine: false
};

const rules = {
};

const handlers = {
  onFormSubmitAfterValidation: async (values, metadata) => {
    try {
      // do nothing
      // no DB call
    } catch (error) {
      return error
    }
  }
}

export default withFormValidation(initialValues, rules, handlers, FilterEntriesFormNewSkeleton);
