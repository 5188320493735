import React from 'react';
import { withRigiContext } from "../context/rigiContext"
import { getTimeSinceTimestamp, getMinutesSinceTimestamp, getFirstnameFromUserKey } from '../../rigiUtils.js';
import { withForegroundContext } from "../context/ForegroundContext"

class DevicesBox extends React.Component {

	constructor(props) {
		super(props);
	}

	getDeviceStateText(deviceState) {
		if (getMinutesSinceTimestamp(deviceState.lastChangedTime) > 60) {
			return <p className="last-changed-state state-offline">offline</p>
		}
		else if (!deviceState.isTurnedOn) {
			return <p className="last-changed-state state-heizt-nicht">heizt nicht</p>
		}
		else {
			return <p className="last-changed-state state-heizt">heizt</p>
		}
	}

	render() {
		const {component, data, settingsLastChanged} = this.props;
		
		return (
			<div className="box box-boiler">
				<div className="box-inner">
					<div className="last-changed-wrapper">
						<p className="last-changed-setting">Aktualisiert vor { getTimeSinceTimestamp(settingsLastChanged.time) } von {getFirstnameFromUserKey(this.props.rigiContext.allRigiUsers, settingsLastChanged.userKey)}</p>
						{ data.state && this.getDeviceStateText(data.state) }
					</div>
					{ React.cloneElement(component, {data: data}) }
				</div>
			</div>
		);
	}
}

export default withRigiContext(withForegroundContext(DevicesBox));
